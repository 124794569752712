export default /* @ngInject */ class NewUICtrl {
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    closeClicked() {
        this.$mdDialog.cancel();
    }

    changeUIClicked() {
        this.$mdDialog.hide(true);
    }
}
