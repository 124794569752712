import NewUICtrl from './newui.controller.js';
import NewUITmpl from './newui.tmpl.html';
import angular from "angular";

export default /* @ngInject */ class NewUIService {
    constructor($window, $mdDialog, TeamCalClientService) {
        this.$window = $window;
        this.$mdDialog = $mdDialog;
        this.TeamCalClientService = TeamCalClientService;
    }

    show() {
        this.$mdDialog.show({
                controller: NewUICtrl,
                controllerAs: 'ctrl',
                template: NewUITmpl,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
            }).then((enabled) => {
                this.TeamCalClientService.updateNewUI(enabled).then(() => {
                    this.$window.location.reload();
                });
        });
    }
}
